<template lang="pug">
  component(:is="layout")
    router-view(v-if='list_ready')
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      current_tab: 'links',
      list_ready: false
    }
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'default-layout'
    },
  },
  async mounted() {
    await this.GET_LIST_WORDS_FIREBASE()
    this.list_ready = true
  },
  methods: {
    ...mapActions({
      GET_LIST_WORDS_FIREBASE: 'list_words/GET_LIST_WORDS_FIREBASE',
    }),
    setsSelectedTab(tab_name) {
      this.current_tab = tab_name
    },
  },
}
</script>
