<template>
  <div class="LinksInNewTabs">
    <div class="container">
      <div class="searchGoogle">
        <WordDay/>
        <SearchGoogle/>
      </div>
      <ListLink/>
    </div>
  </div>
</template>
<script>
import WordDay from '@/components/words/WordDay'
import SearchGoogle from '@/components/search/SearchGoogle'
import ListLink from '@/components/links/ListLink'

export default  {
  name: 'LinksInNewTabs',
  components: {
    WordDay,
    SearchGoogle,
    ListLink,
  },
}
</script>
<style lang="scss">
.LinksInNewTabs {
  .LinksWrapper {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    margin: 3vh auto 0;
    justify-content: center;
    border: 2px solid #bbb;

    a {
      font-size: 24px;
      color: #fff;
      font-weight: 600;
      text-decoration: none;
      padding: 10px;
      background-color: #111;
      border: #000;
      margin: 0 10px 10px;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &-Block {
    width: 600px;
    max-width: 100%;
    margin: 50px auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-Item {
    .cardLink {
      margin-bottom: 0;
      padding-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      width: 112px;
      height: 112px;
      text-decoration: none;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }

      &:visited {
        color: #fff;
      }

      .LinkImg {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: rgba(42, 42, 42, 1);
        margin-bottom: 8px;
      }

      .LinkName {
        color: #fff;
        padding-top: 2px;
        font-size: 13px;
      }
    }
  }
  .searchGoogle {
    margin: 1vh auto 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    button {
      padding: 5px 10px;
      font-size: 18px;
    }
    input{
      background-color: #fff;
      border-radius: 30px;
      border: none;
      padding: 5px 15px;
      color: rgba(0, 0, 0, 0.87);
      word-wrap: break-word;
      outline: none;
      display: flex;
      flex: 100%;
      -webkit-tap-highlight-color: transparent;
      margin-bottom: 20px;
      height: 34px;
      font-size: 26px;
    }
    .searchWordInput {
      background-color: #fff;
      width: 500px;
      max-width: 100%;
      border-radius: 30px;
      border: none;
      padding: 5px 15px;
      color: rgba(0, 0, 0, 0.87);
      word-wrap: break-word;
      outline: none;
      display: flex;
      flex: 100%;
      -webkit-tap-highlight-color: transparent;
      margin-bottom: 20px;
      height: 34px;
      font-size: 26px;
    }
  }
}
</style>
