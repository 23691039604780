import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
  updateDoc,
} from 'firebase/firestore/lite'
import { db } from '@/utilites/firebaseConfig'

export default {
  async GET_LIST_WORDS_FIREBASE({ commit }) {
    const citiesCol = collection(db, 'english')
    const response = await getDocs(citiesCol)
    // const wordsList = response.docs.map(doc => {return {data_doc: doc.data(), doc_id: doc.id}});
    const wordsList = response.docs.map(doc => {
      return {
        ...doc.data(),
        doc_id: doc.id,
      }
    })
    commit('SET_LIST_WORDS_FIREBASE', wordsList)
  },
  UPDATE_DATA_LIST_WORDS_FIREBASE({ commit }, data) {
    console.log('UPDATE_DATA_LIST_WORDS_FIREBASE')
    const englishRef = doc(db, 'english', `${data.doc_id}`)
    updateDoc(englishRef, {
      last_update: Date.now().toString(),
    })
  },
  async ADD_WORD_TO_LIST_FIREBASE({ commit }, data) {
    await setDoc(doc(db, 'english', Date.now().toString()), data)
  },
  async EDIT_WORD_TO_LIST_FIREBASE({ commit }, data) {
    const englishRef = doc(db, 'english', `${data.doc_id}`)
    updateDoc(englishRef, {
      last_update: data.last_update,
      category_word: data.category_word,
      translate: data.translate,
      word: data.word,
    })
  },
  async DELETE_WORD_FROM_LIST_FIREBASE({ commit }, id_document) {
    await deleteDoc(doc(db, 'english', id_document.toString()))
  },
}
