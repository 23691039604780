import {
  collection,
    doc,
  getDocs,
  setDoc,

} from 'firebase/firestore/lite'
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'
import { db } from '@/utilites/firebaseConfig'

export default {
  async ADD_LINK_SITES_FIREBASE({ commit, dispatch }, data) {
    const metadata = {
      contentType: 'image/jpeg',
    }
    const file = data.url_img
    const storage = getStorage()
    const storageRef = ref(storage, 'images_links/' + file.name)
    const uploadTask = uploadBytesResumable(storageRef, file, metadata)
    let file_link
    await uploadTask.on(
      'state_changed',
      snapshot => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        console.log('Upload is ' + progress + '% done')
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused')
            break
          case 'running':
            console.log('Upload is running')
            break
        }
      },
      error => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // eslint-disable-next-line
            console.log(error.code)
            // User doesn't have permission to access the object
            break
          case 'storage/canceled':
            // eslint-disable-next-line
            console.log(error.code)
            // User canceled the upload
            break
          case 'storage/unknown':
            // eslint-disable-next-line
            console.log(error.code)
            // Unknown error occurred, inspect error.serverResponse
            break
        }
      },
      async () => {
        // Upload completed successfully, now we can get the download URL
        await getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
          file_link = downloadURL
        })
        const data_save = {
          name_link: data.name_link,
          link: data.link,
          url_img: file_link,
        }
        await setDoc(doc(db, 'links_sites', Date.now().toString()), data_save)
        dispatch('GET_LIST_LINKS_FIREBASE')
      },
    )
  },

  async GET_LIST_LINKS_FIREBASE({ commit }) {
    const links = collection(db, 'links_sites')
    const response = await getDocs(links)
    const wordsList = response.docs.map(doc => {
      return {
        ...doc.data(),
        doc_id: doc.id,
      }
    })
    commit('SET_LIST_LINKS_FIREBASE', wordsList)
  },
}
