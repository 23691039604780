import Vue from "vue";
import VueRouter from "vue-router";
import LinksInNewTabs from "@/views/LinksInNewTabsPage"
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: LinksInNewTabs,
  },
  {
    path: '/vocabulary',
    name: 'vocabulary',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "ListWords" */ '../views/VocabularyWordsPage.vue'
      ),
    // meta: {
    //   layout: 'empty-layout'
    // }
  },
  {
    path: '/test_words',
    name: 'test_words',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TestWords" */ '../views/TestWordsPage.vue'),
    // meta: {
    //   layout: 'empty-layout'
    // }
  },
  {
    path: '/add_link',
    name: 'add_link_page',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "AddLinkPage" */ '../views/AddLinkPage.vue'),
    // meta: {
    //   layout: 'empty-layout'
    // }
  },
  {
    path: '/add_word',
    name: 'add_word_page',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "AddLinkPage" */ '../views/AddWordPage.vue'),
    // meta: {
    //   layout: 'empty-layout'
    // }
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


export default router
