<template>
  <div class="HeaderNav">
    <nav class="ListTabs">
      <router-link to="/">Links</router-link>
      <router-link to="/vocabulary">Vocabulary</router-link>
      <router-link to="/test_words">Test Words</router-link>
      <router-link to="/add_link">Add Link</router-link>
      <router-link to="/add_word">Add Word</router-link>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'HeaderNav',
  components: {},
}
</script>
<style lang="scss">
.HeaderNav {
  .ListTabs {
    border-bottom: 2px solid #cccccc;
    width: 100%;

    a {
      margin: 0 20px;
      text-decoration: none;
      padding: 5px 20px;
      font-size: 20px;
      display: inline-block;
      color: #25fd59;

      &:hover {
        opacity: 0.7;
      }

      &.router-link-active {
        color: #a7fd25;
      }
    }
  }
}
</style>
