<template>
  <div class='SearchGoogle'>
    <input
      class='searchWordInput'
      type="text"
      name="google"
      v-model="search_word"
      @keyup.enter="searchGoggle"
      id="google_search"
    />
    <button @click="searchGoggle">search</button>
  </div>
</template>

<script>
export default {
  name: 'SearchGoogle',
  data() {
    return {
      search_word: '',
    }
  },
  methods:{
    searchGoggle() {
      window.open(
        `https://www.google.com/search?q=${this.search_word}`,
        '_self',
        'toolbar=0,location=0,menubar=0',
      )
    },
  }
}
</script>

<style lang='scss' scoped>

</style>
