import { getFirestore } from 'firebase/firestore/lite'
import { initializeApp } from 'firebase/app'
const firebaseConfig = {
  apiKey: 'AIzaSyA87nIm3l5zPrTz04KVzKE_pkH51ygBFhw',
  authDomain: 'english-b0a82.firebaseapp.com',
  databaseURL:
    'https://english-b0a82-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'english-b0a82',
  storageBucket: 'english-b0a82.appspot.com',
  messagingSenderId: '829240879647',
  appId: '1:829240879647:web:5641e538f6b536edac832a',
}
const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
