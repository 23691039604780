<template>
  <div class="DefaultLayout">
    <HeaderNav />
    <div class="container">
      <slot />
    </div>
  </div>
</template>
<script>
import HeaderNav from '@/components/headers/HeaderNav'
export default {
  name: 'DefaultLayout',
  components: { HeaderNav },
}
</script>
