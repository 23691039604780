<template>
  <div class='LinksInNewTabs-Block'>
    <p
      class='LinksInNewTabs-Item'
      v-for='link in LIST_LINKS_FIREBASE'
      :key='link.doc_id'
    >
      <a class='cardLink' :href='link.link'>
            <span class='LinkImg'>
              <img :src='link.url_img' alt='' />
            </span>
        <span class='LinkName'>{{ link.name_link }}</span>
      </a>
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ListLink',
  created() {
    this.GET_LIST_LINKS_FIREBASE()
  },
  computed: {
    ...mapGetters({ LIST_LINKS_FIREBASE: 'link_sites/LIST_LINKS_FIREBASE' }),
  },
  methods: {
    ...mapActions({
      GET_LIST_LINKS_FIREBASE: 'link_sites/GET_LIST_LINKS_FIREBASE',
    }),
  },
}
</script>

<style lang='scss' scoped>

</style>
