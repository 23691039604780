<template>
  <div class='WordDay'>
    <div class='WordBlock'>
      <p class='wordDayForCheck'>{{ wordDay.word }}</p>
      <p class='answer' :style='{color: color}'>{{ answer }}</p>
      <p class='flex items-center'>
        <input class='wordDayInput block mr-2 mb-5 text-center w-[120px] mx-auto' @keyup.enter='checkWord()' type='text'
               v-model='translate' />
        <select name='' id='' @input='selectInput' v-model='current_caregory'
                class=' flex h-[34px] mb-5 min-w-[150px] text-[18px] rounded-2xl px-2  border-r-2'>
          <option value='all'>All</option>
          <option v-for='caregory in listCategories' :key='caregory'>{{ caregory }}</option>
        </select>
      </p>
    </div>
    <div class='buttonsBlock'>
      <button @click='checkWord()'>перевірка</button>
      <button @click='prevWord()'> &larr;</button>
      <button @click='nextWord()'> &rarr;</button>
      <button @click='clearTest()'>очистити</button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WordDay',
  data() {
    return {
      id_day_word: 0,
      translate: null,
      answer: null,
      color: null,
      current_caregory: null,
    }
  },
  mounted() {
    const wd = window.localStorage.getItem('wd')
    if (wd) {
      this.id_day_word = wd

    } else {
      window.localStorage.setItem('wd', 0)
    }
    this.current_caregory = window.localStorage.getItem('caregoryWord')
  },
  computed: {
    ...mapGetters({
      LIST_WORDS_FIREBASE: 'list_words/LIST_WORDS_FIREBASE',
    }),
    listCategories() {
      const list_words = []
      this.LIST_WORDS_FIREBASE.forEach(word => {
        if (word.category_word && !list_words.includes(word.category_word)) {
          list_words.push(word.category_word)
        }
      })
      return list_words
    },
    wordDay() {
      return this.LIST_WORDS_FIREBASE.filter(word => {
        if (this.current_caregory === 'all') return true
        return word.category_word === this.current_caregory
      })?.[this.id_day_word] || {}
    },
  },
  watch: {
    wordDay(val) {
      if (!val.word) {
        this.id_day_word = 0
        window.localStorage.setItem('wd', 0)
      }
    },
  },
  methods: {
    selectInput(val) {
      window.localStorage.setItem('caregoryWord', val.target.value)
      // console.log('selectInput', val.target.value)
    },
    checkWord() {
      if (this.translate.toLowerCase() === this.wordDay.translate.toLowerCase()) {
        this.color = '#25fd59'
        this.answer = 'вірно'
      } else {
        this.color = 'red'
        this.answer = 'помилка'
      }
    },
    nextWord() {
      this.clearTest()
      window.localStorage.setItem('wd', Number(this.id_day_word) + 1)
      this.id_day_word = window.localStorage.getItem('wd')
    },
    prevWord() {
      this.clearTest()
      window.localStorage.setItem('wd', Number(this.id_day_word) - 1)
      this.id_day_word = window.localStorage.getItem('wd')
    },
    clearTest() {
      this.translate = null
      this.answer = null
    },
  },
}
</script>
<style lang='scss' scoped>
.buttonsBlock {
  display: flex;
  justify-content: center;
  //flex-direction: column;
  align-items: center;

  button {
    width: 110px;
  }

}

.WordBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .answer {
    padding: 5px 0;
    text-transform: uppercase;
    height: 30px;
    font-size: 18px;
  }

  .wordDayForCheck {
    font-size: 18px;
  }

  .wordDayInput {
    //text-align: center;
    //margin: 0 auto 20px;
    //width: 200px;
  }
}

.WordDay {
  padding-top: 20px;
  margin-bottom: 20px;
}
</style>
