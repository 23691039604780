import Vue from "vue";
import Vuex from "vuex";
import layouts from './layouts'
import list_words from './list_words'
import link_sites from '@/store/link_sites'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    layouts,
    list_words,
    link_sites,
  },
})
